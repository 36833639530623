<template>
    <div class="fuse">
        <img class="boximg" src="../../assets/integration/fuse/1.png" alt="" srcset="">
        <jobnav name="产教融合学院" />
        <div class="fuseimg" @mouseenter="hoverimg=!hoverimg" @mouseleave="hoverimg=!hoverimg">
            <img v-show="hoverimg" src="../../assets/integration/fuse/2.png" alt="">

        </div>
        <div class="oneimgs" v-show="!hoverimg"  @mouseenter="hoverimg=!hoverimg" @mouseleave="hoverimg=!hoverimg">
            <img  src="../../assets/integration/fuse/5.png" alt="">
        </div>
        <jobnav name="产教融合共同体" />
        <div class="fuseimg2">
            <img src="../../assets/integration/fuse/3.png" alt="">
        </div>
        <jobnav name="产教融合项目群" />
        <div class="fusemain">
            <div class="fusemainone" v-for="(item,index) in fusearr" :key="index">
                <div class="fusemainleft">
                    <div class="fusemainlefticon">
                        <img :src="item.img" alt="">
                        <div>{{item.title}}</div>
                    </div>
                    <div class="fusemainindex">0{{index+1}}</div>
                </div>
                <div class="fusemainright">
                    <div class="fusemainrighticon" v-for="(itemr,indexr) in item.size" :key="indexr">
                        <img src="../../assets/integration/fuse/icon/icon.png" alt="">
                        <div>{{itemr}}</div>
                    </div>
                </div>
            </div>
        </div>
        <jobnav name="专业共建" />
        <div class="fuseimg">
            <img src="../../assets/integration/fuse/4.png" alt="">
        </div>
        <jobnav name="教研开发" />
        <div class="research">
            <div class="researchone" v-for="(item,index) in researcharr" :key="index">
                <div class="researchimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="researchtitle">{{item.title}}</div>
            </div>
        </div>
        <jobnav name="现代学徒制" />
        <div class="learndi">
            <img src="../../assets/integration/fuse/icon/13.png" alt="">
        </div>
        <jobnav name="现代学徒制合作单位" />
        <div class="learnbox">
            <div class="learnone" v-for="(item,index) in learnlist" :key="index">
                <div class="learnimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="learntext">{{item.title}}</div>
            </div>
        </div>

    </div>
</template>
<script>
import jobnav from "../../components/jobnav.vue";
import alljson from "../../store/alljson";

export default {
    components: {
        jobnav,
    },
    data() {
        return {
            hoverimg: true,
            fusearr: [],
            researcharr: [
                {
                    img: require("../../assets/integration/fuse/icon/7.png"),
                    title: "婴幼儿卫生与保健",
                },
                {
                    img: require("../../assets/integration/fuse/icon/8.png"),
                    title: "母婴护理",
                },
                {
                    img: require("../../assets/integration/fuse/icon/9.png"),
                    title: "感觉统合训练",
                },
                {
                    img: require("../../assets/integration/fuse/icon/10.png"),
                    title: "奥尔夫音乐教学法",
                },
                {
                    img: require("../../assets/integration/fuse/icon/11.png"),
                    title: "蒙台梭利教学法",
                },
                {
                    img: require("../../assets/integration/fuse/icon/12.png"),
                    title: "1+X幼儿照护",
                },
            ],
            learnlist: [
                {
                    img: require("../../assets/integration/fuse/icon/14.png"),
                    title: "湛江幼儿师范专科学校",
                },
                {
                    img: require("../../assets/integration/fuse/icon/15.png"),
                    title: "广东女子职业技术学院",
                },
                {
                    img: require("../../assets/integration/fuse/icon/16.png"),
                    title: "广东茂名幼儿师范专科学校",
                },
            ],
        };
    },
    created() {
        this.fusearr = alljson.fusearr;
    },
};
</script>
<style lang="scss" scoped>
.fuse {
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .fuseimg {
        width: 70vw;
        margin: 30px auto;
        img {
            width: 100%;
        }
    }
    .oneimgs {
        width: 87.3vw;
        margin: 30px auto;
        transform: translateX(9px);
        img {
            width: 100%;
        }
    }
    .fuseimg2 {
        width: 60vw;
        margin: 30px auto;
        img {
            width: 100%;
        }
    }
    .fusemain {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        // justify-content: space-between;
        .fusemainone {
            // width: 100%;
            height: 200px;
            box-shadow: 3px 0px 3px rgba(190, 65, 63, 1);
            display: flex;
            .fusemainleft {
                width: 40%;
                height: 200px;
                background: #be413f;
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 18px;
                position: relative;
                .fusemainlefticon {
                    margin: 0 auto;

                    text-align: center;
                    img {
                        width: 60px;
                    }
                }
                .fusemainindex {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                }
            }
            .fusemainright {
                width: 60%;
                padding: 20px;
                .fusemainrighticon {
                    display: flex;
                    font-size: 18px;
                    align-self: center;
                    margin-bottom: 6px;
                    img {
                        align-self: center;

                        width: 11px;
                        height: 11px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .research {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        .researchone {
            position: relative;
            .researchimg {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .researchtitle {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                color: #fff;
                font-size: 18px;
                padding: 15px;
                background: linear-gradient(
                    95deg,
                    rgba(255, 103, 0, 0.48) 0%,
                    rgba(255, 103, 0, 0) 100%
                );
            }
        }
    }
    .learndi {
        width: 50vw;
        margin: 30px auto;
        img {
            width: 100%;
        }
    }
    .learnbox {
        width: 80vw;
        margin: 30px auto;
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        .learnone {
            display: flex;
            align-items: center;
            border: 1px solid #e6e6e6;
            padding: 20px;
            // width: 26%;
            .learnimg {
                width: 134px;
                height: 134px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .learntext {
                font-size: 20px;
                opacity: 1;
                margin-left: 20px;
            }
        }
    }
}
</style>